<template>
  <div>
    <b-button
      size="sm"
      variant="outline-primary"
      @click="addTicket"
    >Add New</b-button>
    <br>
    <br>
    <b-card
      v-if="!isMobile"
      no-body
      class="card-company-table"
    >
      <b-row>
        <b-col md="12">
          <b-card
            no-body
            class="card-company-table"
          >
            <b-table
              v-if="!isLoading"
              :items="timeline"
              show-empty
              responsive
              :fields="fields"
              class="mb-0"
              :total-rows="rows"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #cell(status)="data">
                <b-badge
                  v-if="data.item.finish_time"
                  pill
                  variant="light-success"
                >Finish</b-badge>
                <b-badge
                  v-else
                  pill
                  variant="light-danger"
                >On Progress</b-badge>
              </template>
              <template #cell(action)="action">
                <b-link
                  title="Upgrade"
                  @click="viewDetail(action.item.schedule_id)"
                >
                  <span>Detail</span>
                </b-link>
              </template>
            </b-table>
            <br>
            <b-pagination
              v-if="!isLoading"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              class="mt-12"
              pills
              align="center"
            />
          </b-card>
          <template v-if="isLoading">
            <loading />
          </template>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="isMobile">
      <div v-if="isLoading">
        <loading />
      </div>
      <template v-if="!isLoading">
        <b-card
          v-for="(data, index) of timelineMobile"
          :key="index"
          no-body
          class="card-company-table"
        >
          <!-- @click="viewDetail(data.schedule_id)" -->
          <div
            class="card-body"
          >
            <b-row>
              <b-col md="12">
                <h4
                  style="margin-bottom:0.8rem; margin-left:-0.2rem;"
                >
                  <span class="badge badge-light-primary text-wrap">
                    {{ data.title_alias_name }}
                  </span>
                </h4>
                <b-row>
                  <div class="col-12">
                    <strong>{{ $t("form.page.ticket.Ticket Number") }}</strong>
                  </div>
                  <div class="col-12">
                    {{ data.ticket_number }}
                  </div>
                </b-row>
                <b-row>
                  <div class="col-12">
                    <strong>{{ $t("form.page.ticket.Service Impact") }}</strong>
                  </div>
                  <div class="col-12">
                    {{ data.service_impact }}
                  </div>
                </b-row>
                <b-row>
                  <div class="col-12">
                    <strong>{{ $t("form.page.ticket.Category") }}</strong>
                  </div>
                  <div class="col-12">
                    {{ data.alias_name }}
                  </div>
                </b-row>
                <!-- <b-row>
                  <div class="col-12">
                    <strong>{{ $t("form.page.ticket.Description") }}</strong>
                  </div>
                  <div class="col-12">
                    <p
                      v-html="data.schedule_task"
                    />
                  </div>
                </b-row> -->
                <!--div> No : {{data.ticket_number}} </div>
                  <div> category: {{data.name}} </div-->
                <br>
                <span
                  style="float:left"
                  class="card-text text-muted font-small-2"
                >{{ data.date_inserted }}</span>
                <span
                  style="float:right;"
                  class="card-text text-muted font-small-2"
                ><b-badge
                   v-if="data.finish_time"
                   pill
                   variant="light-success"
                 >Finish</b-badge>
                  <b-badge
                    v-else
                    pill
                    variant="light-danger"
                  >On Progress</b-badge></span>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </template>
    </div>

    <b-modal
      id="form-add"
      centered
      title="Add Ticket Form"
      hide-footer
    >
      <validation-observer ref="simpleRules">
        <b-form ref="ticket_form">
          <b-row>
            <b-col md="12">
              <b-form-group :label="$t('form.page.ticket.Service Category')">
                <validation-provider
                  #default="{ errors }"
                  name="Service Type"
                  rules="required"
                >
                  <b-form-select
                    v-model="type"
                    :options="typeList"
                    :state="errors.length > 0 ? false : null"
                    @change="changeType"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >-- Please select --</b-form-select-option>
                    </template>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group :label="$t('form.page.ticket.type')">
                <validation-provider
                  #default="{ errors }"
                  name="Service Category"
                  rules="required"
                >
                  <b-form-select
                    v-model="service_category"
                    :options="serviceCategoryList"
                    :state="errors.length > 0 ? false : null"
                    @change="changeProblem"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >-- Please select --</b-form-select-option>
                    </template>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group :label="$t('form.page.ticket.Service Impact')">
                <validation-provider
                  #default="{ errors }"
                  name="Service Impact"
                  rules="required"
                >
                  <b-form-select
                    v-model="service_impact"
                    :options="serviceImpactList"
                    :state="errors.length > 0 ? false : null"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >-- Please select --</b-form-select-option>
                    </template>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="service_impact == 2 && service_category == 50">
            <b-col md="12">
              <b-form-group :label="$t('form.page.ticket.Period')">
                <validation-provider
                  #default="{ errors }"
                  name="Period"
                  rules="required"
                >
                  <b-form-select
                    v-model="service_period"
                    :options="servicePeriodList"
                    :state="errors.length > 0 ? false : null"
                    @change="changeNTiviDescription"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >-- Please select --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group :label="$t('form.page.ticket.Description')">
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <b-form-textarea
                    id="textarea-default"
                    v-model="description"
                    :placeholder="$t('form.page.ticket.Description')"
                    rows="3"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >Request</b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="view-detail"
      scrollable
      size="lg"
      title="History Ticket"
      hide-footer
    >
      <br>
      <template v-if="isLoadingHistory">
        <loading />
      </template>
      <app-timeline v-if="!showemptyHistory">
        <app-timeline-item
          v-for="(t, index) in historyList"
          :key="index"
          variant="primary"
        >
          <b-media style="margin-bottom:10px;">
            <template #aside>
              <feather-icon
                v-if="t.status == 1"
                size="24"
                :icon="'SettingsIcon'"
              />
              <feather-icon
                v-if="t.status == 2"
                size="24"
                :icon="'TwitchIcon'"
              />
            </template>
            <div v-html="t.activity" />
          </b-media>
        </app-timeline-item>
      </app-timeline>
      <div
        v-if="showemptyHistory"
        style="text-align:center;"
      >
        no history
      </div>
    </b-modal>
  </div>
</template>
<style>
.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
</style>
<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { isMobile } from 'mobile-device-detect'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BCardBody,
  BTable,
  BImg,
  BCard,
  BLink,
  BBadge,
  BMedia,
  BMediaAside,
  BCardTitle,
  BPagination,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import Loading from '@core/components/loading/Loading.vue'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    ToastificationContent,
    BFormTextarea,
    // HistoryTiket,
    BCardBody,
    BTable,
    BImg,
    BCard,
    Loading,
    BLink,
    BBadge,
    BMedia,
    BMediaAside,
    AppTimeline,
    AppTimelineItem,
    BCardTitle,
    BPagination,
    BFormSelectOption,
  },
  data() {
    return {
      emailValue: '',
      currentPage: 1,
      isMobile,
      perPage: 10,
      name: '',
      required,
      original_description: null,
      description: null,
      service_impact: null,
      service_category: null,
      service_period: null,
      type: null,
      serviceImpactList: [],
      servicePeriodList: [],
      typeList: [],
      historyList: [],
      tempServiceCategoryList: [],
      serviceCategoryList: [],
      timeline: [],
      timelineMobile: [],
      timeline_day: null,
      timeline_date: null,
      timeline_month: null,
      isLoading: false,
      isLoadingHistory: false,
      isLoadingDropdown: false,
      showemptyHistory: false,
      fields: [
        { key: 'ticket_number', label: 'Ticket Number' },
        { key: 'alias_name', label: 'Category' },
        { key: 'title_alias_name', label: 'Tipe' },
        { key: 'service_impact', label: 'Impact' },
        { key: 'date_inserted', label: 'Date inserted' },
        { key: 'status', label: 'Status' },
        // { key: "action", label: "action" },
      ],
      items: [
        { id: 1, first_name: 'Fred', last_name: 'Flintstone' },
        { id: 2, first_name: 'Wilma', last_name: 'Flintstone' },
        { id: 3, first_name: 'Barney', last_name: 'Rubble' },
        { id: 4, first_name: 'Betty', last_name: 'Rubble' },
        { id: 5, first_name: 'Pebbles', last_name: 'Flintstone' },
        { id: 6, first_name: 'Bamm Bamm', last_name: 'Rubble' },
        { id: 7, first_name: 'The Great', last_name: 'Gazzoo' },
        { id: 8, first_name: 'Rockhead', last_name: 'Slate' },
        { id: 9, first_name: 'Pearl', last_name: 'Slaghoople' },
      ],
    }
  },
  computed: {
    rows() {
      return this.timeline.length
    },
  },
  mounted() {
    const route = this.$route.path.split('/')
    this.init(route)
  },
  methods: {
    init(route) {
      if (route[2] != null) {
        this.$bvModal.show('form-add')
      }

      if (route[2] == 'Terminate') {
        this.type = 2
        this.service_category = 15
      } else if (route[2] == 'Upgrade') {
        this.type = 2
        this.service_category = 14
      } else if (route[2] == 'Add') {
        this.type = 2
        this.service_category = 11
      }

      if (route[3] == '1') {
        this.type = 2
        this.service_impact = 3
      } else if (route[3] == '2') {
        this.type = 2
        this.service_impact = 1
      } else if (route[3] == '3') {
        this.type = 2
        this.service_impact = 2
      } else if (route[3] == '6') {
        this.type = 2
        this.service_impact = 1
      } else if (route[3] == '4') {
        this.type = 2
        this.service_impact = 1
      }

      if (route[1] !== 'ticket') {
        this.original_description = decodeURIComponent(route[4])
        this.description = decodeURIComponent(route[4])
      }
      this.getTicket()
      this.getDropdown()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (confirm('Are you sure ?')) {
            // eslint-disable-next-line
            const route = this.$route.path.split('/')
            axios
              .post('ticket/send', {
                nik: 'customer',
                title: this.service_category,
                category: this.type,
                service_impact: this.service_impact,
                service_period: this.service_period,
                customer_code: localStorage.getItem('customer_code'),
                description: this.description,
                id_price_catalog_detail: route[5],

                // service_impact: this.service_impact,
                // service_category: this.service_category,
                // description: this.description,
                // working_type: this.type,
              })
              .then(response => {
                this.service_impact = null
                this.service_category = null
                this.description = null
                // this.$refs.ticket_form.reset();

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Load Dashboard',
                    icon: 'EditIcon',
                    variant: 'success',
                    text: response.data.message,
                  },
                })
              })
              .catch(error => {
                if (error.response.data.message == 'Expired token') {
                  localStorage.clear()
                  this.$router.push({ name: 'login' })
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Ticket',
                      icon: 'EditIcon',
                      variant: 'success',
                      text: 'Session Expired, Please Login Again',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Ticket',
                      icon: 'EditIcon',
                      variant: 'success',
                      text: error.response.data.message,
                    },
                  })
                }
              })
              .finally(() => {
                this.isLoading = false
                this.getTicket()
              })
            this.$bvModal.hide('form-add')
            this.type = null
            this.service_category = null
            this.service_impact = null
            this.description = null
          }
        }
      })
    },
    getDropdown() {
      this.isLoadingDropdown = true
      const token = localStorage.getItem('token')
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      axios
        .get('ticket/dropdown')
        .then(response => {
          this.serviceImpactList = response.data.service_impact
          this.tempServiceCategoryList = response.data.service_category
          this.servicePeriodList = response.data.service_period
          if (this.type == 2) {
            this.serviceCategoryList = this.tempServiceCategoryList.filter(
              x => parseInt(x.type) === parseInt(this.type),
            )
          }
          // this.serviceCategoryList = response.data.service_category;
          this.typeList = response.data.working_type

          const route = this.$route.path.split('/')
          if (route[1] !== 'ticket') {
            this.typeList = this.typeList.filter(item => item.value == 5)
          }
        })
        .catch(error => {
          if (error.response.data.message == 'Expired token') {
            localStorage.clear()
            this.$router.push({ name: 'login' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket',
                icon: 'EditIcon',
                variant: 'success',
                text: 'Session Expired, Please Login Again',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket',
                icon: 'EditIcon',
                variant: 'success',
                text: error.response.data.message,
              },
            })
          }
        })
        .finally(() => {
          this.isLoadingDropdown = false
        })
    },
    getTicket() {
      this.isLoading = true
      const token = localStorage.getItem('token')
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      axios
        .get('ticket/ticket_list')
        .then(response => {
          this.timeline = response.data.ticket

          if (this.isMobile) {
            this.timelineMobile = this.timeline.slice(0, 5)
          }

          this.timeline_day = response.data.ticket_day
          this.timeline_date = response.data.ticket_date
          this.timeline_month = response.data.ticket_month
          this.isLoading = false

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ticket',
              icon: 'EditIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
        })
        .catch(error => {
          if (error.response.data.message == 'Expired token') {
            localStorage.clear()
            this.$router.push({ name: 'login' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket',
                icon: 'EditIcon',
                variant: 'success',
                text: 'Session Expired, Please Login Again',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket',
                icon: 'EditIcon',
                variant: 'success',
                text: error.response.data.message,
              },
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    gethistory(schedule_id) {
      this.isLoadingHistory = true
      this.historyList = []
      const token = localStorage.getItem('token')
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      axios
        .get(`ticket/ticket_history/${schedule_id}`)
        .then(response => {
          this.historyList = response.data.data
          if (!this.historyList) {
            this.showemptyHistory = true
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ticket',
              icon: 'EditIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
        })
        .catch(error => {
          if (error.response.data.message == 'Expired token') {
            localStorage.clear()
            this.$router.push({ name: 'login' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket',
                icon: 'EditIcon',
                variant: 'success',
                text: 'Session Expired, Please Login Again',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket',
                icon: 'EditIcon',
                variant: 'success',
                text: error.response.data.message,
              },
            })
          }
        })
        .finally(() => {
          this.isLoadingHistory = false
        })
    },
    changeType() {
      this.serviceCategoryList = this.tempServiceCategoryList.filter(
        x => parseInt(x.type) === parseInt(this.type),
      )

      const route = this.$route.path.split('/')
      if (route[1] !== 'ticket') {
        const token = localStorage.getItem('token')
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
        axios.get(`products/check_flag/${route[3]}`).then(response => {
          const { flag } = response.data.data
          if (flag == 4) {
            this.serviceCategoryList = this.serviceCategoryList.filter(item => item.value == '50')
          } else {
            this.serviceCategoryList = this.serviceCategoryList.filter(item => ['50', '78'].includes(item.value))
          }
        }).catch(error => {
          if (error.response.data.message == 'Expired token') {
            localStorage.clear()
            this.$router.push({ name: 'login' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket',
                icon: 'EditIcon',
                variant: 'success',
                text: 'Session Expired, Please Login Again',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket',
                icon: 'EditIcon',
                variant: 'success',
                text: error.response.data.message,
              },
            })
          }
        }).finally(() => {
          this.isLoadingDropdown = false
        })
      }

      this.service_category = null
    },
    changeProblem() {
      const route = this.$route.path.split('/')

      if (route[1] !== 'ticket') {
        this.isLoadingDropdown = true
        const token = localStorage.getItem('token')
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
        axios.get(`products/check_flag/${route[3]}`).then(response => {
          const { flag } = response.data.data
          if (flag == 4) {
            this.serviceImpactList = this.serviceImpactList.filter(item => item.value == 2)
          } else {
            this.serviceImpactList = this.serviceImpactList.filter(item => item.value == 1)
          }
        }).catch(error => {
          if (error.response.data.message == 'Expired token') {
            localStorage.clear()
            this.$router.push({ name: 'login' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket',
                icon: 'EditIcon',
                variant: 'success',
                text: 'Session Expired, Please Login Again',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket',
                icon: 'EditIcon',
                variant: 'success',
                text: error.response.data.message,
              },
            })
          }
        }).finally(() => {
          this.isLoadingDropdown = false
        })
      }
    },
    addTicket() {
      this.$bvModal.show('form-add')
    },
    viewDetail(schedule_id) {
      // this.gethistory(schedule_id);
      // this.$bvModal.show('view-detail');
      this.$router.push({
        name: 'ticket-history',
        params: {
          schedule_id,
        },
      })
    },
    changeNTiviDescription() {
      if (this.service_period) {
        this.description = `${this.original_description} For ${this.service_period} Bulan`
      } else {
        this.description = this.original_description
      }
    },
  },
}
</script>
