<template>
  <b-card>
    <b-button
      variant="primary"
      size="lg"
    >
      Upgrade Plan
    </b-button> &nbsp;
    <b-button
      variant="outline-danger"
      size="lg"
    >
      Cancel Subscription
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem, BButton,
} from 'bootstrap-vue'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
  },
  props: {
    customerCode: {
      type: String,
      default: null,
    },
  },
  methods: {
    getActive() {
      if (confirm('Are you sure want to activating NTivi ?')) {
        axios.post('third_party/third_party_activation', { customer_code: this.customerCode, service_type: 'NTivi' }).then(response => {
          if (response.data.message == 'Aktivation Successfull') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Aktivation NTivi',
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Aktivation NTivi',
                icon: 'EditIcon',
                variant: 'danger',
                text: response.data.message,
              },
            })
          }
          this.$emit('reloadParent')
        }).catch(error => {}).finally(() => { })
      }
    },
  },
}
</script>
