<template>
  <b-card>
    <div
      v-if="!isLoading"
      class="d-flex justify-content-center"
    >
      <b-button
        variant="primary"
        size="lg"
        style="width: 300px;"
        @click="getActive()"
      >
        Activating My Account
      </b-button>
    </div>
    <loading v-if="isLoading" />
  </b-card>
</template>

<script>
import {
  BCard, BImg, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem, BButton,
} from 'bootstrap-vue'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loading from '@core/components/loading/Loading.vue'

export default {
  components: {
    Loading,
    BCard,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
  },
  props: {
    customerCode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    getActive() {
      if (confirm('Are you sure want to activating NTivi ?')) {
        this.isLoading = true
        axios.post('third_party/third_party_activation', { customer_code: this.customerCode, service_type: 'NTivi' }).then(response => {
          if (response.data.message == 'Aktivation Successfull') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Aktivation NTivi',
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.isLoading = false
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Aktivation NTivi',
                icon: 'EditIcon',
                variant: 'danger',
                text: response.data.message,
              },
            })
          }
          this.$emit('reloadParent')
        }).catch(error => {}).finally(() => { })
      }
    },
  },
}
</script>
