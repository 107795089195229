<template>
  <div
    v-if="Object.keys(profileData).length"
    id="user-profile"
  >
    <ntivi-header :header-data="profileData.header" />
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import NtiviHeader from './NtiviHeader.vue'
// import ProfileAbout from './ProfileAbout.vue'
// import ProfileSuggestedPages from './ProfileSuggestedPages.vue'
// import ProfileTwitterFeed from './ProfileTwitterFeed.vue'
// import ProfilePost from './ProfilePost.vue'
// import ProfileLatestPhotos from './ProfileLatestPhotos.vue'
// import ProfileSuggestion from './ProfileSuggestion.vue'
// import ProfilePolls from './ProfilePolls.vue'
// import profileBottom from './profileBottom.vue'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,

    NtiviHeader,
    // ProfileAbout,
    // ProfileSuggestedPages,
    // ProfileTwitterFeed,
    // ProfilePost,
    // ProfileLatestPhotos,
    // ProfileSuggestion,
    // ProfilePolls,
    // profileBottom,
  },
  data() {
    return {
      profileData: {
        header: {
          avatar: require('@/assets/images/portrait/small/avatar-s-2.jpg'),
          username: 'Kitty Allanson',
          designation: 'UI/UX Designer',
          coverImg: require('@/assets/images/banner/banner.png'),
        },
      },
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import "@core/scss/vue/pages/page-profile.scss";
</style>
