<template>
  <div>
    <loading v-if="isLoadingStatus" />
    <!-- modal start-->
    <b-modal ref="modalNtiviChannel" id="ntiviModals" header-border-variant="no-border text-center" footer-border-variant="no-border" footer-bg-variant="light" :no-close-on-backdrop="true" :no-close-on-esc="true" size="xl">
      <div slot="modal-header" class="text-center">
        <h3 class="text-center">
          Channels {{ selectedChannel }}
        </h3>
      </div>
      <b-row>
        <b-col v-for="(dt_channel, index_channel) in listChannel" :key="'chn_'+index_channel" md="2" sm="12" lg="2" xl="2">
          <div style="text-align: center;" >
            <img :src="dt_channel['logo_url']" :alt="dt_channel['name']">
          </div>
        </b-col>
      </b-row>
      <div slot="modal-footer" class="float-right">
          <b-button type="button" size="sm" variant="danger" @click="closePreview"><i class="fa fa-undo"></i> Close</b-button>
      </div>
    </b-modal>
    <!-- end modal -->
    <div v-if="showSecurity">
      <h1 v-if="!isLoadingStatus">
        Current Package
      </h1>
      <b-row v-if="!isLoadingStatus">
        <b-col
          v-for="(data_current, idx_current) in DataCurrent"
          :key="'curr'+idx_current"
          md="4"
          sm="12"
          xl="3"
        >
          <b-card>
            <h3 style="text-align: center;">
              {{ data_current["name"] }}
            </h3>
            <b-list-group class="list-group-circle text-left">
              <template>
                <b-list-group-item @click="goToChannel(data_current['product_code'])" style="cursor: pointer;" class="pricing-basic-value font-weight-bolder text-primary">
                  {{ data_current["channel_count"] }} Channel
                </b-list-group-item>
              </template>
            </b-list-group>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <b-tabs
      v-if="!isLoadingStatus"
      pills
      class="profile-tabs mt-1 mt-md-0"
      nav-class="mb-0"
    >
      <!-- Activation tab -->
      <b-tab
        :active="showActivation"
        :disabled="isDisable"
        @click="getContent(1)"
      >
        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Activation</span>
        </template>
        <br>
        <tab-activation
          v-if="showActivation"
          :customer-code="customerCode"
          @reloadParent="init"
        />
      </b-tab>
      <!--/ Activation tab -->
      <!-- Security tab -->
      <b-tab
        :active="showSecurity"
        :disabled="showActivation"
        @click="getContent(2)"
      >
        <!-- title -->
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Security</span>
        </template>
        <br>
        <tab-security
          v-if="showSecurity"
          :customer-code="customerCode"
          :paket-ntivi="paketNtivi"
          :all-ntivi="allNtivi"
        />
      </b-tab>
      <!--/ Security tab -->
      <!-- Plan tab -->
      <!-- <b-tab
              :active="showPlan"
              @click="getContent(3)"
              :disabled="showActivation"
            > -->
      <!-- title -->
      <!-- <template #title>
                <feather-icon icon="InfoIcon" size="18" class="mr-50" />
                <span class="font-weight-bold">Plan</span>
              </template>
              <br>
              <tab-plan v-if="showPlan" :customerCode="customerCode" @reloadParent="init"></tab-plan>
            </b-tab> -->
      <!--/ Plan tab -->

    </b-tabs>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTabs, BButton, BTab, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
// tab
import Loading from '@core/components/loading/Loading.vue'
import TabActivation from './tabs/Activation.vue'
import TabSecurity from './tabs/Security.vue'
import TabPlan from './tabs/Plan.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    BButton,
    TabActivation,
    TabSecurity,
    TabPlan,
    BListGroup,
    BListGroupItem,
    Loading,
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      customerCode: localStorage.getItem('customer_code'),
      showActivation: false,
      showSecurity: false,
      showPlan: false,
      isDisable: false,
      isLoadingStatus: false,
      DataCurrent: [],
      paketNtivi: null,
      allNtivi: [],
      listChannel: [],
      selectedChannel: ""
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.isLoadingStatus = true
      this.allChannelNtivi()
    },
    goToChannel(id) {
      // Filter array berdasarkan product code 
      const getChannel = this.DataCurrent.filter(kode => kode.product_code == id); 
      this.listChannel = getChannel[0].channel_list; 
      this.selectedChannel = getChannel[0].ntivi_name;
      this.$refs.modalNtiviChannel.show();
      return id;
    },
    closePreview: function () {
      this.$refs.modalNtiviChannel.hide();
    },
    allChannelNtivi() {
      axios.get('third_party/ntivi_channel').then(response => {
        this.allNtivi = response.data.data
      }).catch(error => {}).finally(() => { this.getCurrentProduct() })
    },
    getCurrentProduct() {
      axios.get('products/current-package-ntivi').then(response => {
        this.DataCurrent = response.data.data
        this.paketNtivi = response.data.ntivi_product_id 
        // Mengambil 'all Ntivi'
        const listChn = this.allNtivi

        // Filter array untuk mengambil channel_count & channel_list
        this.DataCurrent.forEach(fe => {
          const abc = listChn.filter(c => c.ntivi_package_code == fe.ntivi_package_code)
          if (abc.length > 0) {
            fe.channel_list = abc[0].channel_list
          }
        })
        // console.log(this.DataCurrent)
      }).catch(error => {
        if (error.response.data.message == 'Expired token') {
          localStorage.clear()
          this.$router.push({ name: 'login' })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Products',
              icon: 'EditIcon',
              variant: 'success',
              text: 'Session Expired, Please Login Again',
            },
          })
        }
      }).finally(() => {
        this.checkActive()
      })
    },
    checkActive() {
      axios.post('third_party/status_activation', { customer_code: this.customerCode, service_type: 'NTivi' }).then(response => {
        this.isDisable = response.data.isDisable
        if (this.isDisable) {
          this.showActivation = false
          this.showSecurity = true
          this.showPlan = false
          this.isLoadingStatus = false
        } else {
          this.showActivation = true
          this.showSecurity = false
          this.showPlan = false
          this.isLoadingStatus = false
        }
      }).catch(error => {}).finally(() => {})
    },
    getContent(e) {
      switch (e) {
        case 1:
          this.showActivation = true
          this.showSecurity = false
          this.showPlan = false
          break
        case 2:
          this.showActivation = false
          this.showSecurity = true
          this.showPlan = false
          break
        case 3:
          this.showActivation = false
          this.showSecurity = false
          this.showPlan = true
          break
      }
    },
  },
}
</script>
