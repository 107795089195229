<template>
  <div>
    <b-card>
      <b-button
        v-if="!isLoading"
        variant="primary"
        size="lg"
        @click="getActive()"
      >
        Reset Password
      </b-button>
      <loading v-if="isLoading" />
    </b-card>
    <div
      v-for="(data, index) in DataPrice"
      :key="index"
    >
      <products-card
        v-if="!isLoading"
        :data-detail="data"
        :title="index"
        :subscribe="data['Monthly Fee']"
        :action="actionPage"
        :current-ntivi="paketNtivi"
      />
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem, BButton,
} from 'bootstrap-vue'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loading from '@core/components/loading/Loading.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import ProductsCard from '../../products/ProductsCard.vue'

export default {
  components: {
    Loading,
    BRow,
    BCol,
    BCard,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
    Swiper,
    SwiperSlide,
    ProductsCard,
  },
  props: {
    customerCode: {
      type: String,
      default: null,
    },
    paketNtivi: {
      type: String,
      default: null,
    },
    allNtivi: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      DataPrice: {},
      actionPage: null,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getProduct()
      this.actionPage = 'Add'
    },
    getActive() {
      if (confirm('Are you sure want to reset NTivi password ?')) {
        this.isLoading = true
        axios.post('third_party/reset_password', { customer_code: this.customerCode, service_type: 'NTivi' }).then(response => {
          if (response.data.message == 'Reset Password Successfull') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Reset Password NTivi',
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.isLoading = false
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Reset Password NTivi',
                icon: 'EditIcon',
                variant: 'danger',
                text: response.data.message,
              },
            })
          }
          this.$emit('reloadParent')
        }).catch(error => {}).finally(() => { })
      }
    },

    getProduct() {
      this.isLoading = true
      axios.get('products/all').then(response => {
        this.DataPrice = response.data.data
        this.DataPrice = Object.keys(this.DataPrice)
          .filter(key => key === 'TV') // Hanya ambil kunci yang sama dengan 'TV'
          .reduce((obj, key) => {
            obj[key] = this.DataPrice[key] // Masukkan kategori 'TV' ke dalam objek baru
            return obj
          }, {})

        // Mengambil array 'Monthly Fee' dari 'TV'
        const monthlyFees = this.DataPrice.TV['Monthly Fee']

        // Mengambil 'all Ntivi'
        const listChn = this.allNtivi

        // Filter array untuk mengambil channel_count & channel_list
        this.DataPrice.TV['Monthly Fee'] = monthlyFees.filter(fee => fee.flag_product == 4)
        this.DataPrice.TV['Monthly Fee'].forEach(fe => {
          const abc = listChn.filter(c => c.product_code == fe.product_code)
          if (abc.length > 0) {
            fe.channel_count = abc[0].channel_count
            fe.channel_list = abc[0].channel_list
          }
        })
        // console.log(this.DataPrice.TV['Monthly Fee']);
      }).catch(error => {
        if (error.response.data.message == 'Expired token') {
          localStorage.clear()
          this.$router.push({ name: 'login' })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Products',
              icon: 'EditIcon',
              variant: 'success',
              text: 'Session Expired, Please Login Again',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Products',
              icon: 'EditIcon',
              variant: 'success',
              text: error.response.data.message,
            },
          })
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>
