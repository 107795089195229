<template>
  <div>
    <!-- modal start-->
    <b-modal ref="modalForm" id="addModals" header-border-variant="no-border text-center" footer-border-variant="no-border" footer-bg-variant="light" :no-close-on-backdrop="true" :no-close-on-esc="true" size="xl">
      <div slot="modal-header" class="text-center">
        <h3 class="text-center">
          Channels <strong>{{ selectedChannel }}</strong>
        </h3>
      </div>
      <b-row>
        <b-col v-for="(dt_channel, index_channel) in listChannel" :key="'chn_'+index_channel" md="2" sm="12" lg="2" xl="2">
          <div style="text-align: center;" >
            <img :src="dt_channel['logo_url']" :alt="dt_channel['name']">
          </div>
        </b-col>
      </b-row>
      <div slot="modal-footer" class="float-right">
          <b-button type="button" size="sm" variant="danger" @click="cancelAdd"><i class="fa fa-undo"></i> Close</b-button>
      </div>
    </b-modal>
    <!-- end modal -->
    <div class="text-center">
      <h1 class="mt-5">{{ $t("form.page.product.Pricing Plan") }} {{ title }}</h1>
      <br />
    </div>

    <b-row class="pricing-card" v-if="ukuran < 1200 || ukuran > 1400">
      <b-col offset-sm-2 sm="12" md="12" offset-lg="2" lg="12" class="mx-auto">
        <b-row v-swiper:mySwiper="swiperOptions">
          <div class="swiper-wrapper" v-if="subscribe.length > 0">
            <b-col
              md="4"
              sm="12"
              xl="3"
              class="swiper-slide"
              v-for="(data, index) in subscribe"
              :key="index"
            >
              <b-card align="center">
                <!-- img -->
                <!--b-img v-if="pricing.basicPlan.img" :src="pricing.basicPlan.img" class="mb-2 mt-5" alt="basic svg img"/-->
                <!--/ img -->
                <h3 style="margin-bottom: 30px; height: 40px">{{ data["name"] }}</h3>

                <!-- annual plan -->
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">IDR</sup>
                    <span
                      class="pricing-basic-value font-weight-bolder text-primary"
                      style="font-size: 2.5rem"
                      >{{ formatPrice(data["price"]) }}</span
                    >
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold"
                      >/month</sub
                    >
                  </div>
                </div>
                <!--/ annual plan -->

                <!-- plan benefit -->
                <b-list-group class="list-group-circle text-left">
                  <template v-if="action !== 'Upgrade' && data['flag_product'] != 4">
                    <b-list-group-item v-for="(dataBenefit, index) in dataDetail['Installation Fee']" :key="index">
                      {{ dataBenefit.name + " IDR " + formatPrice(dataBenefit.price) }}
                      <br />
                      <small v-if="dataBenefit.flag == 1" class="annual-pricing text-muted">(One Time)</small>
                    </b-list-group-item>
                  </template>
                  <b-list-group-item
                    v-if="
                      data['name'].trim() !== 'Abodement' &&
                      data['name'].trim() !== 'Happy Package' &&
                      data['name'].trim() !== 'Festival Package'
                    "
                  >
                    <span v-if="data['flag_product'] != 4">{{ "Internet Up to " + data["name"].replace("Broadband", "") }}</span>
                    <span v-if="data['flag_product'] == 4">{{ "Upgrade to " + data["name"].replace("Broadband", "") }}</span>
                  </b-list-group-item>
                </b-list-group>
                <!--/ plan benefit -->

                <!-- channels -->
                <b-list-group class="list-group-circle text-left" v-if="data['flag_product'] == 4">
                  <template>
                    <b-list-group-item @click="goToChannel(data['product_code'])">
                      <span style="cursor: pointer;" class="pricing-basic-value font-weight-bolder text-primary">{{ data['channel_count'] }} Channel</span> 
                    </b-list-group-item>
                  </template>
                </b-list-group>
                <!--/ channels -->

                <!-- buttons -->
                <b-button
                  v-if="data['flag_product'] == 4" 
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  class="mt-2"
                  variant="primary"
                  @click="actionButton(title, data, action)"
                  :disabled="true"
                >
                  {{ action }}
                </b-button>
                <b-button
                  v-else
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  class="mt-2"
                  variant="primary"
                  @click="actionButton(title, data, action)"
                >
                  {{ action }}
                </b-button>
              </b-card>
            </b-col>
          </div>


           <!-- <div
            class="swiper-wrapper"
            v-if="subscribe.length === 0 && dataDetail['Installation Fee'].length > 0"
          >
            <b-col
              md="4"
              class="swiper-slide"
              v-for="(data, index) in dataDetail['Installation Fee']"
              :key="index"
            >
              <b-card align="center">
                <h3 style="margin-bottom: 30px; height: 40px">{{ data["name"] }}</h3>
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">IDR</sup>
                    <span
                      class="pricing-basic-value font-weight-bolder text-primary"
                      style="font-size: 2.5rem"
                      >{{ formatPrice(data["price"]) }}</span
                    >
                    <sub
                      v-if="data.flag == 1"
                      class="pricing-duration text-body font-medium-1 font-weight-bold"
                      >/One Time</sub
                    >
                  </div>
                </div>
             
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  class="mt-2"
                  variant="primary"
                  @click="actionButton(title, data, action)"
                >
                  Upgrade
                </b-button>
              </b-card>
            </b-col>
          </div> -->
      
          <div slot="button-next" class="swiper-button-next" style="right: -2px" />
          <div slot="button-prev" class="swiper-button-prev" style="left: -2px" />
        </b-row>
      </b-col>
    </b-row>
    <b-row class="pricing-card" v-if="ukuran >= 1200 && ukuran <= 1400">
      <b-col offset-sm-2 sm="12" md="12" offset-lg="2" lg="12" class="mx-auto">
        <b-row v-swiper:mySwiper="swiperOptions">
          <div class="swiper-wrapper" v-if="subscribe.length > 0">
            <b-col
              md="4"
              sm="12"
              xl="4"
              class="swiper-slide"
              v-for="(data, index) in subscribe"
              :key="index"
            >
              <b-card align="center">
                <!-- img -->
                <!--b-img v-if="pricing.basicPlan.img" :src="pricing.basicPlan.img" class="mb-2 mt-5" alt="basic svg img"/-->
                <!--/ img -->
                <h3 style="margin-bottom: 30px; height: 40px">{{ data["name"] }}</h3>

                <!-- annual plan -->
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">IDR</sup>
                    <span
                      class="pricing-basic-value font-weight-bolder text-primary"
                      style="font-size: 2.5rem"
                      >{{ formatPrice(data["price"]) }}</span
                    >
                    <sub class="pricing-duration text-body font-small-1 font-weight-bold"
                      >/month</sub
                    >
                  </div>
                </div>
                <!--/ annual plan -->

                <!-- plan benefit -->
                <b-list-group class="list-group-circle text-left">
                  <template v-if="action !== 'Upgrade' && data['flag_product'] != 4">
                    <b-list-group-item v-for="(dataBenefit, index) in dataDetail['Installation Fee']" :key="index">
                      {{ dataBenefit.name + " IDR " + formatPrice(dataBenefit.price) }}
                      <br />
                      <small v-if="dataBenefit.flag == 1" class="annual-pricing text-muted">(One Time)</small>
                    </b-list-group-item>
                  </template>
                  <b-list-group-item
                    v-if="
                      data['name'].trim() !== 'Abodement' &&
                      data['name'].trim() !== 'Happy Package' &&
                      data['name'].trim() !== 'Festival Package'
                    "
                  >
                    <span v-if="data['flag_product'] != 4">{{ "Internet Up to " + data["name"].replace("Broadband", "") }}</span>
                    <span v-if="data['flag_product'] == 4">{{ "Upgrade to " + data["name"].replace("Broadband", "") }}</span>
                  </b-list-group-item>
                </b-list-group>
                <!--/ plan benefit -->

                <!-- channels -->
                <b-list-group class="list-group-circle text-left" v-if="data['flag_product'] == 4">
                  <template>
                    <b-list-group-item @click="goToChannel(data['product_code'])">
                      <span style="cursor: pointer;" class="pricing-basic-value font-weight-bolder text-primary">{{ data['channel_count'] }} Channel</span> 
                    </b-list-group-item>
                  </template>
                </b-list-group>
                <!--/ channels -->

                <!-- buttons -->
                <b-button
                  v-if="data['flag_product'] == 4 && parseInt(data['product_category_id']) <= parseInt(currentNtivi)" 
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  class="mt-2"
                  variant="primary"
                  @click="actionButton(title, data, action)"
                  :disabled="true"
                >
                  {{ action }}
                </b-button>
                <b-button
                  v-else
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  class="mt-2"
                  variant="primary"
                  @click="actionButton(title, data, action)"
                >
                  {{ action }}
                </b-button>
              </b-card>
            </b-col>
          </div>


           <!-- <div
            class="swiper-wrapper"
            v-if="subscribe.length === 0 && dataDetail['Installation Fee'].length > 0"
          >
            <b-col
              md="4"
              class="swiper-slide"
              v-for="(data, index) in dataDetail['Installation Fee']"
              :key="index"
            >
              <b-card align="center">
                <h3 style="margin-bottom: 30px; height: 40px">{{ data["name"] }}</h3>
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">IDR</sup>
                    <span
                      class="pricing-basic-value font-weight-bolder text-primary"
                      style="font-size: 2.5rem"
                      >{{ formatPrice(data["price"]) }}</span
                    >
                    <sub
                      v-if="data.flag == 1"
                      class="pricing-duration text-body font-medium-1 font-weight-bold"
                      >/One Time</sub
                    >
                  </div>
                </div>
             
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  class="mt-2"
                  variant="primary"
                  @click="actionButton(title, data, action)"
                >
                  Upgrade
                </b-button>
              </b-card>
            </b-col>
          </div> -->
      
          <div slot="button-next" class="swiper-button-next" style="right: -2px" />
          <div slot="button-prev" class="swiper-button-prev" style="left: -2px" />
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import axios from "@axios";
import Ticket from "../ticket/Ticket.vue";
import { dataTool } from "echarts/lib/echarts";

/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
    Swiper,
    SwiperSlide,
    Ticket,
  },
  props: {
    dataDetail: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
    subscribe: {
      type: Array,
      default: () => [],
    },
    action: {
      type: String,
      default: "",
    },
    currentNtivi: {
      type: String,
      default: "",
    }
  },
  directives: {
    Ripple,
    swiper: directive,
  },
  data() {
    return {
      ukuran: window.innerWidth,
      status: "monthly",
      showTicketForm: false,
      monthlyPlanShow: true,
      selectedChannel: "",
      planBenefits: [
        "100 responses a month",
        "Unlimited forms and surveys",
        "Unlimited fields",
        "Basic form creation tools",
        "Up to 2 subdomains",
      ],
      swiperOptions: {
        //slidesPerView: "auto",
        autoHeight: true,
        //centeredSlides: true,
        //spaceBetween: 20,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      listChannel: [] 
    };
  },
  methods: {
    init: function () {
      this.showTicketForm = false;
    },
    cancelAdd: function () {
      this.$refs.modalForm.hide();
    },
    goToChannel(id) {
      // Filter array berdasarkan product code 
      const getChannel = this.subscribe.filter(kode => kode.product_code == id); 
      this.listChannel = getChannel[0].channel_list; 
      this.selectedChannel = getChannel[0].name;
      this.$refs.modalForm.show();
      return id;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    actionButton(title, param, act) {
      //this.showTicketForm = true;
      var display_text = "";
      var action_text = "";
      if (param.name == "Abodement" || param.name == "Installation Fee") {
        display_text = title;
      } else {
        display_text = param.name;
      }
      if (act == "Add") {
        action_text = "Subscribe a";
        this.$router.push({
          name: "product-ticket",
          params: {
            action: "Add",
            product_category_id: param.product_category_id,
            text: "i want to " + action_text + " " + display_text + " Package",
            price_catalog_detail_id:param.price_catalog_detail_id
          },
        });
      } else if (act == "Upgrade") {
        action_text = "Upgrade to";
        this.$router.push({
          name: "product-ticket",
          params: {
            action: "Upgrade",
            product_category_id: param.product_category_id,
            text: "i want to " + action_text + " " + display_text + " Package",
            price_catalog_detail_id:param.price_catalog_detail_id
          },
        });
      }

      // axios.get("profile/data").then((response) => {
      //   var sendto = '6285811371894';
      //   //var sendto = '628998161865';
      //   if(response.data.address.street_address != null){
      //     var address = response.data.address.street_address.value+'\r\n';
      //   }else{
      //     var address = '';
      //   }
      //   if(response.data.address.phone_number != null){
      //     var phone_number = response.data.address.phone_number.value+'\r\n';
      //   }else{
      //     var phone_number = '';
      //   }
      //   if(response.data.address.email != null){
      //     var email = response.data.address.email.value+'\r\n';
      //   }else{
      //     var email = '';
      //   }
      //   var user_name = localStorage.getItem("name");
      //   var customer_code = localStorage.getItem("customer_code");
      //   var end_message = '```this request is generated from customer portal```';

      //   if(param == 'Abodement'){
      //     var message = 'Hi, Im \r\n*'+ user_name +'* _('+ customer_code +')_ \r\n'+phone_number+email+address+' \r\ni want to Subscribe a Telephone Package \r\n'+end_message;
      //     window.open('https://api.whatsapp.com/send?phone='+sendto+'&text='+window.encodeURIComponent(message));
      //   }else if(param == 'Installation Fee'){
      //     var message = 'Hi, Im \r\n*'+ user_name +'* _('+ customer_code +')_ \r\n'+phone_number+email+address+' \r\ni want to Subscribe a '+title+' package\r\n'+end_message;
      //     window.open('https://api.whatsapp.com/send?phone='+sendto+'&text='+window.encodeURIComponent(message));
      //   }else{
      //     var message = 'Hi, Im \r\n*'+ user_name +'* _('+ customer_code +')_ \r\n'+phone_number+email+address+' \r\ni want to Subscribe a '+param+' package\r\n'+end_message;
      //     window.open('https://api.whatsapp.com/send?phone='+sendto+'&text='+window.encodeURIComponent(message));
      //   }
      // });
    },
  },
  computed: {
    // swiper() {
    //  // return this.$refs.mySwiper.$swiper;
    // },
  },

  mounted() {
    this.init();
    // this.swiper.slideTo(3, 1000, false)
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
</style>
